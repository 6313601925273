import { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

import LanguagesContext from '../context/languages.jsx';

const LanguagesTextareas = ({ name, control }) => {
	const { list: languages } = useContext(LanguagesContext);

	return languages.map((language) => {
		return (
			<Controller
				key={language.code}
				name={`${name}.${language.code}`}
				control={control}
				render={({ field }) => <TextField {...field} fullWidth label={language.name} multiline rows={2} />}
			/>
		);
	});
};

export default LanguagesTextareas;
