import React, { useContext } from 'react';

import JSONContext from './json.jsx';

const SectionsContext = React.createContext();

function SectionsProvider({ children }) {
	const { get_sections, set_sections } = useContext(JSONContext);
	const sections = get_sections();

	const store = {
		get list() {
			return sections;
		},

		async add(item) {
			await set_sections([...sections, item]);
		},

		remove(id) {
			sections.splice(id, 1);
			set_sections(sections);
		},

		async set(sections) {
			await set_sections(sections);
		},
	};

	return <SectionsContext.Provider value={store}>{children}</SectionsContext.Provider>;
}

export default SectionsContext;
export { SectionsProvider };
