import React, { useContext } from 'react';

import { Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Stack } from '@mui/material';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

import LanguagesContext from 'src/context/languages.jsx';
import ModalsContext from 'src/context/modals.jsx';

const ViewerList = ({ fields, remove, update }) => {
	if (!fields?.length) return <></>;

	const modals_context = useContext(ModalsContext);
	const { def_lang } = useContext(LanguagesContext);

	return (
		<Grid item xs={12}>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell sx={{ pl: 3 }}>Name</TableCell>
							<TableCell align="left">Subheader</TableCell>
							<TableCell align="left">Description</TableCell>
							<TableCell align="right" sx={{ pr: 3 }}>
								Actions
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{fields.map(({ name, sub, desc }, index) => (
							<TableRow key={index}>
								<TableCell sx={{ pl: 3 }}>
									<Typography align="left" variant="subtitle1">
										{name[def_lang]}
									</Typography>
								</TableCell>

								<TableCell align="left">
									<Typography align="left" variant="subtitle1">
										{sub[def_lang]}
									</Typography>
								</TableCell>

								<TableCell align="left">
									<Typography align="left" variant="subtitle1">
										{desc[def_lang]}
									</Typography>
								</TableCell>

								<TableCell sx={{ pr: 1 }} align="right">
									<Stack direction="row">
										<IconButton
											color="secondary"
											size="large"
											onClick={() =>
												modals_context.show('popup_item_edit', {
													data: fields[index],
													clb: (data) => update(index, data),
												})
											}
										>
											<EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />
										</IconButton>

										<IconButton
											color="error"
											size="large"
											onClick={() =>
												modals_context.show('item_delete', {
													clb: () => remove(index),
												})
											}
										>
											<DeleteTwoToneIcon fontSize="small" />
										</IconButton>
									</Stack>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Grid>
	);
};

export default ViewerList;
