import Map from 'src/components/dashboard_map/map.jsx';
import { FilesProvider } from 'src/context/files.jsx';
import { MapsProvider } from 'src/context/maps.jsx';

const Page = () => {
	return (
		<FilesProvider>
			<MapsProvider>
				<Map />
			</MapsProvider>
		</FilesProvider>
	);
};

export default Page;
