import { SectionsProvider } from 'src/context/sections.jsx';

import Section from 'src/components/dashboard_section/section.jsx';
import { FilesProvider } from 'src/context/files.jsx';

const Page = () => (
	<FilesProvider>
		<SectionsProvider>
			<Section />
		</SectionsProvider>
	</FilesProvider>
);

export default Page;
