import { Button, Box, CircularProgress } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import HomeContext, { HomeProvider } from 'src/context/home.jsx';
import ModalsContext from 'src/context/modals.jsx';

const Home = () => {
	const home_context = useContext(HomeContext);
	const modal_context = useContext(ModalsContext)

	const data = home_context.home;

	if (data === undefined) return <CircularProgress size={100} sx={{ mx: 'auto', mt: 20, display: 'block' }} />

	return <div>
		<Box dangerouslySetInnerHTML={{ __html: data }} />
		<Button variant='contained' onClick={() => modal_context.show('home_edit', {
			data,
			clb: async (data) =>
				await home_context.set(data)
		})}>Edit</Button>
	</div>
};

const Page = () => (
	<HomeProvider>
		<Home />
	</HomeProvider>);

export default Page;
