import { useContext, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Select, MenuItem, Grid, Stack } from '@mui/material';

import InputLabel from 'src/berry/ui-component/extended/Form/InputLabel.jsx';
import { gridSpacing } from 'src/berry/store/constant.jsx';

import MapsContext, { MapsProvider } from 'src/context/maps.jsx';
import FileSelector from '../file_selector/component.jsx';

const activity_labels = {
	'': 'No Activity',
	'map': 'Map',
	'video': 'Video',
};

const def_activity = Object.keys(activity_labels);

const MapsSelect = ({ label, name, control }) => {
	const { list } = useContext(MapsContext);
	const default_value = list?.[0].uid;

	return (
		<>
			<InputLabel>{label ? `${label} ` : ''}Map</InputLabel>
			<Controller
				name={name ? `${name}.map` : 'map'}
				control={control}
				defaultValue={default_value}
				render={({ field }) => (
					<Select displayEmpty {...field} sx={{ width: '100%' }}>
						{list.map(({ uid }) => (
							<MenuItem key={uid} value={uid}>
								{uid}
							</MenuItem>
						))}
					</Select>
				)}
			/>
		</>
	);
};

const VideoSelector = ({ on_change_video, video }) => {
	return (
		<>
			<InputLabel>Video</InputLabel>
			<FileSelector on_change={on_change_video} value={video} type="video" />
		</>
	);
};

const DataSelect = (props) => {
	switch (props.activity) {
		case '':
			return <></>;
		case 'map':
			return (
				<MapsProvider>
					<MapsSelect {...props} />
				</MapsProvider>
			);
		case 'video':
			return <VideoSelector {...props} />;
	}
};

const ActivitySelector = (props) => {
	const { name, setValue, on_change_video, item } = props;
	const get_activity = () => {
		if (item?.map) return 'map';
		else if (item?.video) return 'video';
		return '';
	};

	const [activity, set_activity] = useState(get_activity());

	return (
		<>
			<Grid item xs={6}>
				<Stack spacing={gridSpacing}>
					<InputLabel>Activity</InputLabel>

					<Select
						value={activity}
						onChange={(event) => {
							set_activity(event.target.value);
							setValue(name ? `${name}.map` : 'map', undefined);
							on_change_video(undefined);
						}}
						displayEmpty
					>
						{def_activity.map((activity) => (
							<MenuItem value={activity} key={activity}>
								{activity_labels[activity]}
							</MenuItem>
						))}
					</Select>
				</Stack>
			</Grid>

			<Grid item xs={6}>
				<Stack spacing={gridSpacing}>
					<DataSelect activity={activity} {...props} />
				</Stack>
			</Grid>
		</>
	);
};

export default ActivitySelector;
