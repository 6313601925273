import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

import MainCard from 'src/berry/ui-component/cards/MainCard.jsx';
import LanguagesContext from 'src/context/languages.jsx';
import ModalsContext from 'src/context/modals.jsx';
import MapsContext from 'src/context/maps.jsx';
import { array_move } from 'src/utils/index.js';

const Body = ({ data }) => {
	const navigate = useNavigate();
	const modals_context = useContext(ModalsContext);
	const maps_context = useContext(MapsContext);
	const { def_lang } = useContext(LanguagesContext);

	const handle_replace = (i, direction) => {
		maps_context.set(array_move(data, i, direction));
	};

	return (
		<TableBody>
			{data.map(({ uid, name }, i) => {
				return (
					<TableRow
						hover
						onClick={() => {
							navigate(`/dashboard/maps/${i}`);
						}}
						key={i}
						style={{ cursor: 'pointer' }}
					>
						<TableCell sx={{ pl: 4 }}>{uid}</TableCell>
						<TableCell width="100%">{name?.[def_lang]}</TableCell>

						<TableCell
							sx={{
								display: 'flex',
							}}
						>
							<Box sx={{ width: '100px', position: 'relative' }}>
								<IconButton
									sx={{ float: 'left', display: i === data.length - 1 ? 'none' : '' }}
									color="primary"
									size="large"
									onClick={(event) => {
										event.stopPropagation();
										handle_replace(i, 1);
									}}
								>
									<KeyboardDoubleArrowDownIcon />
								</IconButton>

								<IconButton
									sx={{ float: 'right', display: i === 0 ? 'none' : '' }}
									color="primary"
									size="large"
									onClick={(event) => {
										event.stopPropagation();
										handle_replace(i, -1);
									}}
								>
									<KeyboardDoubleArrowUpIcon />
								</IconButton>
							</Box>

							<IconButton
								color="primary"
								size="large"
								onClick={(event) => {
									event.stopPropagation();
									modals_context.show('section_delete', {
										clb: () => {
											maps_context.remove(i);
										},
									});
								}}
							>
								<DeleteIcon sx={{ fontSize: '1.3rem' }} />
							</IconButton>
						</TableCell>
					</TableRow>
				);
			})}
		</TableBody>
	);
};

const Maps = () => {
	const modals_context = useContext(ModalsContext);
	const maps_context = useContext(MapsContext);

	return (
		<>
			<Button
				variant="contained"
				type="button"
				onClick={() =>
					modals_context.show('map_add', {
						clb: (data) => {
							maps_context.add(data);
						},
					})
				}
				sx={{ mb: 2 }}
			>
				Add maps
			</Button>
			{maps_context.list.length ? (
				<MainCard content={false}>
					<TableContainer>
						<Table style={{ whiteSpace: 'nowrap', width: '100%' }}>
							<TableHead>
								<TableRow>
									<TableCell sx={{ pl: 4 }}>UID</TableCell>
									<TableCell>Name</TableCell>
									<TableCell align="right" sx={{ pr: 4 }}>
										Action
									</TableCell>
								</TableRow>
							</TableHead>
							<Body data={maps_context.list} />
						</Table>
					</TableContainer>
				</MainCard>
			) : (
				<Typography variant="subtitle1" sx={{ color: 'grey.900' }} align="center">
					There are no maps
				</Typography>
			)}
		</>
	);
};

export default Maps;
