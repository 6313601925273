import React, { useState, useContext, useEffect } from 'react';

import UserContext from './user.jsx';

import api_json from '../api/json.js';

import { CircularProgress } from '@mui/material';

const JSONContext = React.createContext();

function JSONProvider({ children }) {
	const [json, json_set] = useState(undefined);
	const { session } = useContext(UserContext);

	const store = {
		get json() {
			return json;
		},
		set_json: async (data) => {
			await api_json.save({ data, session });
			json_set(data);
		},
		get_home() {
			return json?.home;
		},
		set_home: async (home) => {
			await store.set_json({ ...json, home});
		},
		get_languages() {
			return json.languages;
		},
		set_languages: async (languages) => {
			await store.set_json({ ...json, languages });
		},
		get_sections() {
			return json.sections || [];
		},
		set_sections: async (sections) => {
			await store.set_json({ ...json, sections });
		},
		get_popups() {
			return json.popups || [];
		},
		set_popups: async (popups) => {
			await store.set_json({ ...json, popups });
		},
		get_maps() {
			return json.maps || [];
		},
		set_maps: async (maps) => {
			await store.set_json({ ...json, maps });
		},
	};

	useEffect(() => {
		(async () => {
			json_set(await api_json.get({ session }));
		})();
	}, [1]);

	return <JSONContext.Provider value={store}>{children}</JSONContext.Provider>;
}

function JSONDeffer({ children }) {
	const context = useContext(JSONContext);

	if (!context.json) return <CircularProgress size={100} sx={{ mx: 'auto', mt: 20, display: 'block' }} />;

	return children;
}

export default JSONContext;
export { JSONProvider, JSONDeffer };
