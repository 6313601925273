import React, { useContext, useState } from 'react';
import { Button, Box, TextField, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import ModalsContext from '../context/modals.jsx';

const SectionForm = () => {
	const modals_context = useContext(ModalsContext);

	const [uid, set_uid] = useState('');
	const [type, set_type] = useState('timeline');

	const handle_submit = (event) => {
		event.preventDefault();
		modals_context.data.clb({ type, uid });
		modals_context.hide();
	};

	return (
		<form onSubmit={handle_submit}>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<TextField
						autoFocus
						required
						id="section_name"
						name="section_name"
						label="UID"
						fullWidth
						value={uid}
						onChange={(e) => set_uid(e.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth>
						<InputLabel id="type">Type</InputLabel>
						<Select labelId="type" id="type" value={type} label="Type" onChange={(e) => set_type(e.target.value)}>
							<MenuItem value="timeline">Timeline</MenuItem>
							<MenuItem value="small_cards">Small Cards</MenuItem>
							<MenuItem value="big_cards">Big Cards</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			</Grid>
			<Box textAlign="center">
				<Button variant="contained" type="submit" sx={{ mt: 2 }}>
					Add
				</Button>
			</Box>
		</form>
	);
};

export default SectionForm;
