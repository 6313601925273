import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import SectionBigCards from './big_cards.jsx';
import SectionSmallCards from './small_cards.jsx';
import SectionTimeline from './timeline.jsx';
import SectionsContext from 'src/context/sections.jsx';

const section_types = {
	timeline: SectionTimeline,
	small_cards: SectionSmallCards,
	big_cards: SectionBigCards,
};

const Section = () => {
	const { id } = useParams();
	const { list } = useContext(SectionsContext);
	const section = list[id];

	const Component = section_types[section.type];
	return <Component />;
};

export default Section;
