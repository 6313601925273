import React, { useContext } from 'react';

import { IconButton, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import MainCard from '../berry/ui-component/cards/MainCard';

import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';

import LanguagesContext from '../context/languages.jsx';
import ModalsContext from '../context/modals';

const Body = ({ data }) => {
	const modals_context = useContext(ModalsContext);
	const languages_context = useContext(LanguagesContext);

	return (
		<TableBody>
			{data.map(({ code, name }, i) => {
				return (
					<TableRow key={i}>
						<TableCell sx={{ pl: 4 }}>{code}</TableCell>
						<TableCell align="left">{name}</TableCell>
						<TableCell align="center" width="100%">
							<IconButton
								color="primary"
								size="large"
								onClick={() =>
									modals_context.show('language_delete', {
										clb: () => {
											languages_context.remove(i);
										},
									})
								}
							>
								<DeleteIcon sx={{ fontSize: '1.3rem' }} />
							</IconButton>
							<IconButton
								color="secondary"
								size="large"
								onClick={() =>
									modals_context.show('language_edit', {
										data: { code, name },
										clb: (data) => {
											languages_context.edit(data, i);
										},
									})
								}
							>
								<EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />
							</IconButton>
						</TableCell>
					</TableRow>
				);
			})}
		</TableBody>
	);
};

const Languages = () => {
	const modals_context = useContext(ModalsContext);
	const languages_context = useContext(LanguagesContext);

	return (
		<>
			<Button
				variant="contained"
				type="button"
				onClick={() =>
					modals_context.show('language_add', {
						clb: (data) => {
							languages_context.add(data);
						},
					})
				}
				sx={{ mb: 2 }}
			>
				Add language
			</Button>
			{languages_context.list.length ? (
				<MainCard content={false}>
					<TableContainer>
						<Table style={{ whiteSpace: 'nowrap', width: '100%' }}>
							<TableHead>
								<TableRow>
									<TableCell sx={{ pl: 4 }}>Language code</TableCell>
									<TableCell align="left" width="100%">
										Language name
									</TableCell>
									<TableCell align="center" sx={{ pr: 4 }}>
										Action
									</TableCell>
								</TableRow>
							</TableHead>
							<Body data={languages_context.list} />
						</Table>
					</TableContainer>
				</MainCard>
			) : (
				<Typography variant="subtitle1" sx={{ color: 'grey.900' }} align="center">
					There are no languages
				</Typography>
			)}
		</>
	);
};

const Page = () => {
	return <Languages />;
};

export default Page;
