import React, { useContext } from 'react';

import ModalsContext from 'src/context/modals.jsx';
import SimpleModal from 'src/berry/ui-component/modals/SimpleModals.jsx';
import LanguageForm from 'src/modals/language_form.jsx';
import SectionForm from 'src/modals/section_form.jsx';
import PopupForm from 'src/modals/popup_form.jsx';
import SectionTimelineSubmenuForm from 'src/modals/section_timeline_submenu_form.jsx';
import ItemDelete from 'src/modals/item_delete.jsx';
import SectionEventForm from 'src/modals/section_event_form.jsx';
import SectionSmallCardsSubmenuForm from 'src/modals/section_small_cards_submenu_form.jsx';
import SectionSmallCardsCardForm from 'src/modals/section_small_cards_card_form';
import SectionBigCardsCardForm from 'src/modals/section_big_cards_card_form';
import PopupItemForm from 'src/modals/popup_item_form';
import MapForm from 'src/modals/map_form';
import PlaceForm from 'src/modals/place_form';
import HomeForm from 'src/modals/home_form';

const big_modal = { width: 'auto', position: 'relative', top: 'unset', left: 'unset', transform: 'none', margin: '50px' };

const _modal2components = new Map([
	['language_delete', { content: <ItemDelete />, title: 'Delete language' }],
	['language_edit', { content: <LanguageForm action="edit" />, title: 'Edit language' }],
	['language_add', { content: <LanguageForm action="add" />, title: 'Add language' }],
	['file_delete', { content: <ItemDelete />, title: 'Delete file' }],
	['section_delete', { content: <ItemDelete />, title: 'Delete section' }],
	['section_add', { content: <SectionForm />, title: 'Add section' }],
	['popup_delete', { content: <ItemDelete />, title: 'Delete popup' }],
	['popup_add', { content: <PopupForm />, title: 'Add popup' }],
	['section_event_add', { content: <SectionEventForm action="add" />, title: 'Add Event', styles: big_modal }],
	['section_event_edit', { content: <SectionEventForm action="edit" />, title: 'Edit Event', styles: big_modal }],
	['section_timeline_submenu_add', { content: <SectionTimelineSubmenuForm action="add" />, title: 'Add Submenu', styles: big_modal }],
	['section_timeline_submenu_edit', { content: <SectionTimelineSubmenuForm action="edit" />, title: 'Edit Submenu', styles: big_modal }],
	['section_small_cards_submenu_add', { content: <SectionSmallCardsSubmenuForm action="add" />, title: 'Add Submenu', styles: big_modal }],
	['section_small_cards_submenu_edit', { content: <SectionSmallCardsSubmenuForm action="edit" />, title: 'Edit Submenu', styles: big_modal }],
	['item_delete', { content: <ItemDelete />, title: 'Delete Item' }],
	['section_small_cards_card_add', { content: <SectionSmallCardsCardForm action="add" />, title: 'Add Card', styles: big_modal }],
	['section_small_cards_card_edit', { content: <SectionSmallCardsCardForm action="edit" />, title: 'Edit Card', styles: big_modal }],
	['section_big_cards_card_add', { content: <SectionBigCardsCardForm action="add" />, title: 'Add Card', styles: big_modal }],
	['section_big_cards_card_edit', { content: <SectionBigCardsCardForm action="edit" />, title: 'Edit Card', styles: big_modal }],
	['popup_item_add', { content: <PopupItemForm action="add" />, title: 'Add Item', styles: big_modal }],
	['popup_item_edit', { content: <PopupItemForm action="edit" />, title: 'Edit Item', styles: big_modal }],
	['map_add', { content: <MapForm />, title: 'Add map' }],
	['maps_place_add', { content: <PlaceForm action="add" />, title: 'Add Map', styles: big_modal }],
	['maps_place_edit', { content: <PlaceForm action="edit" />, title: 'Edit Map', styles: big_modal }],
	['home_edit', { content: <HomeForm />, title: 'Edit home text', styles: big_modal }],
]);

const Modals = () => {
	const { modal, opened, hide } = useContext(ModalsContext);
	const component = _modal2components.get(modal);

	return <SimpleModal open={opened} on_close={hide} content={component?.content} title={component?.title} styles={component?.styles} />;
};

export default Modals;
