import { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Divider, Grid, Stack, TextField, Button } from '@mui/material';

import MainCard from 'src/berry/ui-component/cards/MainCard.jsx';
import InputLabel from 'src/berry/ui-component/extended/Form/InputLabel.jsx';
import { gridSpacing } from 'src/berry/store/constant.jsx';

import SectionsContext from 'src/context/sections.jsx';
import ModalsContext from 'src/context/modals.jsx';
import LanguagesTextFields from 'src/ui/languages_text_fields.jsx';
import { array_move, get_all_categories } from 'src/utils/index.js';

import SmallCardsSubmenuTable from './small_cards_submenu_table.jsx';
import FileSelector from '../file_selector/component.jsx';
import SmallCardsCardsTable from './small_cards_cards_table.jsx';

const SectionSmallCards = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { list, set } = useContext(SectionsContext);
	const modals_context = useContext(ModalsContext);

	const section = list[id];

	const { handleSubmit, control } = useForm({
		defaultValues: { uid: section.uid, name: section.name },
	});

	const back_to_list = () => navigate('/dashboard/sections/');

	const on_sumbit = (data) => {
		list[id] = { ...list[id], ...data };
		set(list);
		back_to_list();
	};

	const all_categories = get_all_categories(list[id]?.submenu);

	return (
		<MainCard title="Edit Section">
			<form onSubmit={handleSubmit(on_sumbit)}>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12}>
						<Stack spacing={gridSpacing}>
							<Controller
								name="uid"
								control={control}
								defaultValue=""
								render={({ field }) => <TextField {...field} type="text" fullWidth label="UID" />}
							/>
						</Stack>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid item md={12}>
						<Stack spacing={gridSpacing}>
							<InputLabel>Name</InputLabel>
							<LanguagesTextFields name="name" control={control} />
						</Stack>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid item xs={12}>
						<Stack spacing={2}>
							<InputLabel>Background Image</InputLabel>
							<FileSelector
								on_change={(data) => {
									list[id].bkg = data;
									set(list);
								}}
								value={section.bkg}
							/>
						</Stack>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid item xs={12}>
						<InputLabel>Submenu</InputLabel>
					</Grid>

					<SmallCardsSubmenuTable
						fields={section?.submenu}
						remove={(index) => {
							section.submenu.splice(index, 1);
							set(list);
						}}
						update={(index, data) => {
							section.submenu[index] = data;
							set(list);
						}}
						replace={(i, direction) => {
							section.submenu = array_move(section.submenu, i, direction);
							set(list);
						}}
						all_categories={all_categories}
					/>

					<Grid item xs={12}>
						<Button
							variant="text"
							onClick={() =>
								modals_context.show('section_small_cards_submenu_add', {
									clb: (data) => {
										if (!section.submenu) section.submenu = [];
										section.submenu.push(data);
										set(list);
									},
									all_categories,
								})
							}
						>
							Add Submenu
						</Button>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid item xs={12}>
						<InputLabel>Cards</InputLabel>
					</Grid>

					<SmallCardsCardsTable
						fields={section?.cards}
						remove={(index) => {
							section.cards.splice(index, 1);
							set(list);
						}}
						update={(index, data) => {
							section.cards[index] = data;
							set(list);
						}}
						replace={(i, direction) => {
							section.cards = array_move(section.cards, i, direction);
							set(list);
						}}
						all_categories={all_categories}
					/>

					<Grid item xs={12}>
						<Button
							variant="text"
							onClick={() =>
								modals_context.show('section_small_cards_card_add', {
									clb: (data) => {
										if (!section.cards) section.cards = [];
										section.cards.push(data);
										set(list);
									},
									all_categories,
								})
							}
						>
							Add Card
						</Button>
					</Grid>

					<Grid item xs={6}>
						<Button fullWidth variant="text" color="error" onClick={back_to_list}>
							Cancel
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button fullWidth variant="contained" type="submit">
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
		</MainCard>
	);
};

export default SectionSmallCards;
