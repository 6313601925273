import React, { useContext, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Controller } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { Typography, Stack, Button } from '@mui/material';
import 'react-quill/dist/quill.snow.css';

import LanguagesContext from '../context/languages.jsx';

const RawButton = ({ on_click_raw }) => (
	<Button onClick={on_click_raw} sx={{ float: 'right', position: 'absolute', top: '-45px', right: 0 }}>
		Raw
	</Button>
);

const Editor = ({ onChange, value }) => {
	const [editor_html, set_editor_html] = useState(value);
	const [raw_html, set_raw_html] = useState(value);
	const [show_raw, set_show_raw] = useState(false);

	const sync_views = (from_raw) => {
		if (from_raw) set_editor_html(raw_html);
		else set_raw_html(editor_html);
	};

	const handle_change = (html) => {
		set_editor_html(html);
		onChange(html);
	};

	const handle_change_raw = (html) => {
		set_raw_html(html);
		onChange(html);
	};

	const handle_click_show_raw = (event) => {
		event.preventDefault();
		set_show_raw(!show_raw);
		sync_views(show_raw);
	};

	return (
		<>
			<RawButton on_click_raw={handle_click_show_raw} />
			{show_raw ? (
				<textarea
					className={'raw-editor'}
					onChange={(e) => handle_change_raw(e.target.value)}
					value={raw_html}
					multiple
					style={{ minHeight: '178px', width: '100%', marginTop: '20px' }}
				/>
			) : (
				<ReactQuill onChange={handle_change} value={editor_html} theme={'snow'} />
			)}
		</>
	);
};

const LanguagesWysiwygTextarea = ({ name, control }) => {
	const { list: languages } = useContext(LanguagesContext);
	const theme = useTheme();

	return (
		<Stack spacing={5}>
			{languages.map((language) => (
				<Stack
					spacing={5}
					key={language.code}
					sx={{
						'position': 'relative',
						'& .quill': {
							'marginTop': '20px',
							'bgcolor': theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50',
							'borderRadius': '12px',
							'& .ql-toolbar': {
								bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.100',
								borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
								borderTopLeftRadius: '12px',
								borderTopRightRadius: '12px',
							},
							'& .ql-container': {
								'borderColor': theme.palette.mode === 'dark' ? `${theme.palette.dark.light + 20} !important` : 'primary.light',
								'borderBottomLeftRadius': '12px',
								'borderBottomRightRadius': '12px',
								'& .ql-editor': {
									minHeight: 135,
								},
							},
						},
					}}
				>
					<Typography variant="subtitle2">{language.name}</Typography>
					<Controller
						name={`${name}.${language.code}`}
						control={control}
						defaultValue=""
						render={({ field }) => (
							<Editor
								placeholder="Write something"
								value={field.value}
								onChange={(value) => {
									field.onChange(value);
								}}
							/>
						)}
					/>
				</Stack>
			))}
		</Stack>
	);
};

export default LanguagesWysiwygTextarea;
