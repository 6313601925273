import { useContext } from 'react';
import { Divider, Grid, Stack, Button } from '@mui/material';
import { useForm } from 'react-hook-form';

import InputLabel from 'src/berry/ui-component/extended/Form/InputLabel.jsx';
import { gridSpacing } from 'src/berry/store/constant.jsx';

import LanguagesTextFields from 'src/ui/languages_text_fields.jsx';
import LanguagesWysiwygTextarea from 'src/ui/languages_wysiwyg_textarea.jsx';
import ModalsContext from 'src/context/modals.jsx';
import FileSelector from 'src/components/file_selector/component.jsx';
import ActionSelector from 'src/components/action_selector/component.jsx';

const SectionBigCardsCardForm = ({ action }) => {
	const {
		hide,
		data: { data, clb },
	} = useContext(ModalsContext);

	const { handleSubmit, control, setValue, watch } = useForm({ defaultValues: data || {} });

	const on_submit = (data) => {
		clb(data);
		hide();
	};

	return (
		<>
			<Grid container spacing={gridSpacing} sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 250px)', pb: '30px' }}>
				<Grid item md={6}>
					<Stack spacing={gridSpacing}>
						<InputLabel>Header</InputLabel>
						<LanguagesTextFields name="header" control={control} />
					</Stack>
				</Grid>

				<Grid item md={6}>
					<Stack spacing={gridSpacing}>
						<InputLabel>Subheader</InputLabel>
						<LanguagesTextFields name="subheader" control={control} />
					</Stack>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={6}>
					<Stack spacing={gridSpacing}>
						<InputLabel>Image</InputLabel>
						<FileSelector on_change={(img) => setValue('img', img)} value={data?.img} />
					</Stack>
				</Grid>

				<Grid item xs={6}>
					<Stack spacing={gridSpacing}>
						<InputLabel>Small Image</InputLabel>
						<FileSelector on_change={(img) => setValue('small_img', img)} value={data?.small_img} can_clear={true} />
					</Stack>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item md={6}>
					<Stack spacing={gridSpacing}>
						<ActionSelector action={watch('btn.action')} name="btn" control={control} label="Button" setValue={setValue} />
					</Stack>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={12}>
					<Stack spacing={gridSpacing}>
						<InputLabel>Description</InputLabel>
						<LanguagesWysiwygTextarea name="desc" control={control} />
					</Stack>
				</Grid>
			</Grid>

			<Grid container>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item xs={12}>
					<Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end" sx={{ mt: '15px' }}>
						<Button color="error" onClick={hide}>
							Cancel
						</Button>
						<Button variant="contained" size="small" onClick={handleSubmit(on_submit)}>
							{action === 'edit' ? 'Save' : 'Add'}
						</Button>
					</Stack>
				</Grid>
			</Grid>
		</>
	);
};

export default SectionBigCardsCardForm;
