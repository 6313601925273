import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Divider, Grid, Stack, Button } from '@mui/material';

import MainCard from 'src/berry/ui-component/cards/MainCard.jsx';
import InputLabel from 'src/berry/ui-component/extended/Form/InputLabel.jsx';
import { gridSpacing } from 'src/berry/store/constant.jsx';

import ModalsContext from 'src/context/modals.jsx';
import LanguagesTextFields from 'src/ui/languages_text_fields.jsx';
import Modals from 'src/controllers/modals.jsx';
import PopupsContext from 'src/context/popups.jsx';

import ViewerList from './viewer_list.jsx';

const Viewer = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { list, set } = useContext(PopupsContext);
	const modals_context = useContext(ModalsContext);

	const popup = list[id];

	const { back_label } = popup;

	const { handleSubmit, control } = useForm({
		defaultValues: { back_label },
	});

	const back_to_list = () => navigate('/dashboard/popups/');

	const on_sumbit = (data) => {
		list[id] = { ...list[id], ...data };
		set(list);
		back_to_list();
	};

	return (
		<>
			<Modals />
			<MainCard title="Edit Popup">
				<form onSubmit={handleSubmit(on_sumbit)}>
					<Grid container spacing={gridSpacing}>
						<Grid item md={6}>
							<Stack spacing={gridSpacing}>
								<InputLabel>Back Label</InputLabel>
								<LanguagesTextFields name="back_label" control={control} />
							</Stack>
						</Grid>

						<Grid item xs={12}>
							<Divider />
						</Grid>

						<Grid item xs={12}>
							<InputLabel>List</InputLabel>
						</Grid>
						<ViewerList
							fields={popup.list}
							remove={(index) => {
								popup.list.splice(index, 1);
								set(list);
							}}
							update={(index, data) => {
								popup.list[index] = data;
								set(list);
							}}
						/>

						<Grid item xs={12}>
							<Button
								variant="text"
								onClick={() =>
									modals_context.show('popup_item_add', {
										clb: (data) => {
											if (!popup.list) popup.list = [];
											popup.list.push(data);
											set(list);
										},
									})
								}
							>
								Add Item
							</Button>
						</Grid>

						<Grid item xs={12}>
							<Divider />
						</Grid>

						<Grid item xs={6}>
							<Button fullWidth variant="text" color="error" onClick={back_to_list}>
								Cancel
							</Button>
						</Grid>
						<Grid item xs={6}>
							<Button fullWidth variant="contained" type="submit">
								Save
							</Button>
						</Grid>
					</Grid>
				</form>
			</MainCard>
		</>
	);
};

export default Viewer;
