import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Divider, Grid, Stack, Button } from '@mui/material';

import MainCard from 'src/berry/ui-component/cards/MainCard.jsx';
import InputLabel from 'src/berry/ui-component/extended/Form/InputLabel.jsx';
import { gridSpacing } from 'src/berry/store/constant.jsx';

import LanguagesTextFields from 'src/ui/languages_text_fields.jsx';
import PopupsContext from 'src/context/popups.jsx';
import LanguagesWysiwygTextarea from 'src/ui/languages_wysiwyg_textarea.jsx';
import FileSelector from 'src/components/file_selector/component.jsx';
import ActionSelector from 'src/components/action_selector/component.jsx';

import ActivitySelector from '../activity_selector/component.jsx';

const Item = ({ control, index, items, on_change_video, setValue, watch }) => {
	return (
		<>
			<Grid item xs={12}>
				<InputLabel>List Item {index + 1}</InputLabel>
			</Grid>

			<Grid item md={6}>
				<Stack spacing={gridSpacing}>
					<InputLabel>Name</InputLabel>
					<LanguagesTextFields name={`viewer.list[${index}].name`} control={control} />
				</Stack>
			</Grid>

			<Grid item md={6}>
				<Stack spacing={gridSpacing}>
					<InputLabel>Subheader</InputLabel>
					<LanguagesTextFields name={`viewer.list[${index}].sub`} control={control} />
				</Stack>
			</Grid>

			<Grid item xs={12}>
				<Stack spacing={gridSpacing}>
					<InputLabel>Image</InputLabel>
					<FileSelector value={watch(`viewer.list[${index}].img`)} read_only={true} />
				</Stack>
			</Grid>

			<ActivitySelector
				control={control}
				on_change_video={on_change_video}
				video={items?.[index]?.video}
				name={`viewer.list[${index}]`}
				setValue={setValue}
				item={items?.[index]}
			/>

			<Grid item xs={12}>
				<Stack spacing={gridSpacing}>
					<InputLabel>Description</InputLabel>
					<LanguagesWysiwygTextarea name={`viewer.list[${index}].desc`} control={control} />
				</Stack>
			</Grid>

			<Grid item xs={12}>
				<Divider />
			</Grid>
		</>
	);
};

const Regular = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { list, set } = useContext(PopupsContext);

	const popup = list[id];

	const { header, subheader, content, right_text, img, btn_right, btn_left, back_label, left_cards, viewer } = popup;

	const { handleSubmit, control, watch, setValue, getValues } = useForm({
		defaultValues: { header, subheader, content, img, right_text, btn_right, btn_left, back_label, left_cards, viewer },
	});

	const back_to_list = () => navigate('/dashboard/popups/');

	const on_sumbit = (data) => {
		list[id] = { ...list[id], ...data };
		set(list);
		back_to_list();
	};

	return (
		<MainCard title="Edit Popup">
			<form onSubmit={handleSubmit(on_sumbit)}>
				<Grid container spacing={gridSpacing}>
					<Grid item md={6}>
						<Stack spacing={gridSpacing}>
							<InputLabel>Header</InputLabel>
							<LanguagesTextFields name="header" control={control} />
						</Stack>
					</Grid>

					<Grid item md={6}>
						<Stack spacing={gridSpacing}>
							<InputLabel>Subheader</InputLabel>
							<LanguagesTextFields name="subheader" control={control} />
						</Stack>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid item xs={12}>
						<Stack spacing={2}>
							<InputLabel>Main Image</InputLabel>
							<FileSelector
								on_change={(data) => {
									setValue('img', data);
									setValue('viewer.list[0].img', data);
									list[id].img = data;
									set(list);
								}}
								value={getValues('img')}
							/>
						</Stack>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid item xs={12}>
						<Stack spacing={gridSpacing}>
							<InputLabel>Content</InputLabel>
							<LanguagesWysiwygTextarea name="content" control={control} />
						</Stack>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid item xs={12}>
						<Stack spacing={gridSpacing}>
							<InputLabel>Right Text</InputLabel>
							<LanguagesWysiwygTextarea name="right_text" control={control} />
						</Stack>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid item md={6}>
						<Stack spacing={gridSpacing}>
							<ActionSelector
								control={control}
								action={watch('btn_left.action')}
								name="btn_left"
								label="Left Button"
								setValue={setValue}
								actions={['popups.close']}
							/>
						</Stack>
					</Grid>

					<Grid item md={6}>
						<Stack spacing={gridSpacing}>
							<ActionSelector
								control={control}
								action={watch('btn_right.action')}
								name="btn_right"
								label="Right Button"
								setValue={setValue}
								actions={['popups.close']}
							/>
						</Stack>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid item md={6}>
						<Stack spacing={gridSpacing}>
							<InputLabel>Back Label</InputLabel>
							<LanguagesTextFields name="back_label" control={control} />
						</Stack>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid item xs={12}>
						<InputLabel sx={{ fontSize: '1.5em' }}>Left Cards</InputLabel>
					</Grid>

					<Grid item md={6}>
						<Stack spacing={gridSpacing}>
							<InputLabel>First Left Card Text</InputLabel>
							<LanguagesWysiwygTextarea name="left_cards[0].text" control={control} />

							<InputLabel>First Left Card Image</InputLabel>
							<FileSelector
								on_change={(data) => {
									setValue('left_cards[0].img', data);
									setValue('viewer.list[1].img', data);
								}}
								value={getValues('left_cards.[0].img')}
							/>
						</Stack>
					</Grid>
						<Grid item md={6}>
							<Stack spacing={gridSpacing}>
								<InputLabel>Second Left Card Text</InputLabel>
								<LanguagesWysiwygTextarea name="left_cards[1].text" control={control} />

								<InputLabel>Second Left Card Image</InputLabel>
								<FileSelector
									on_change={(data) => {
										setValue('left_cards[1].img', data);
										setValue('viewer.list[2].img', data);
									}}
									value={getValues('left_cards.[1].img')}
								/>
							</Stack>
						</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid item xs={12}>
						<InputLabel sx={{ fontSize: '1.5em' }}>Viewer</InputLabel>
					</Grid>

					<Grid item md={6}>
						<Stack spacing={gridSpacing}>
							<InputLabel>Back Label</InputLabel>
							<LanguagesTextFields name="viewer.back_label" control={control} />
						</Stack>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					{['', '', ''].map((_, index) => (
						<Item
							control={control}
							items={popup.viewer?.list}
							index={index}
							key={index}
							watch={watch}
							on_change_video={(data) => setValue(`viewer.list[${index}].video`, data)}
							setValue={setValue}
						/>
					))}

					<Grid item xs={6}>
						<Button fullWidth variant="text" color="error" onClick={back_to_list}>
							Cancel
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button fullWidth variant="contained" type="submit">
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
		</MainCard>
	);
};

export default Regular;
