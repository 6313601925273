import { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { Select, MenuItem } from '@mui/material';

import InputLabel from 'src/berry/ui-component/extended/Form/InputLabel.jsx';

import PopupsContext, { PopupsProvider } from 'src/context/popups.jsx';
import LanguagesTextFields from 'src/ui/languages_text_fields.jsx';
import MapsContext, { MapsProvider } from 'src/context/maps.jsx';

const def_actions = ['', 'popups.show', 'maps.show'];

const action_labels = {
	'': 'No Action',
	'popups.close': 'Close Popup',
	'popups.show': 'Show Popup',
	'maps.show': 'Show Map',
};

const ButtonLabel = ({ control, name, label }) => {
	return (
		<>
			<InputLabel>{label ? `${label} ` : ''}Label</InputLabel>
			<LanguagesTextFields name={`${name}.label`} control={control} />
		</>
	);
};

const PopupsSelect = ({ label, name, control }) => {
	const { list } = useContext(PopupsContext);
	const default_value = list?.[0].uid;

	return (
		<>
			<InputLabel>{label ? `${label} ` : ''}Popup</InputLabel>
			<Controller
				name={name ? `${name}.data` : 'data'}
				control={control}
				defaultValue={default_value}
				render={({ field }) => (
					<Select displayEmpty {...field} sx={{ width: '100%' }}>
						{list.map(({ uid }) => (
							<MenuItem key={uid} value={uid}>
								{uid}
							</MenuItem>
						))}
					</Select>
				)}
			/>
		</>
	);
};

const MapsSelect = ({ label, name, control }) => {
	const { list } = useContext(MapsContext);
	const default_value = list?.[0].uid;

	return (
		<>
			<InputLabel>{label ? `${label} ` : ''}Map</InputLabel>
			<Controller
				name={name ? `${name}.data` : 'data'}
				control={control}
				defaultValue={default_value}
				render={({ field }) => (
					<Select displayEmpty {...field} sx={{ width: '100%' }}>
						{list.map(({ uid }) => (
							<MenuItem key={uid} value={uid}>
								{uid}
							</MenuItem>
						))}
					</Select>
				)}
			/>
		</>
	);
};

const DataSelect = (props) => {
	const { action, has_label = true } = props;
	switch (action) {
		case '':
			return <></>;
		case 'popups.close':
			return <ButtonLabel {...props} />;
		case 'popups.show':
			return (
				<PopupsProvider>
					{has_label && <ButtonLabel {...props} />}
					<PopupsSelect {...props} />
				</PopupsProvider>
			);
		case 'maps.show':
			return (
				<MapsProvider>
					{has_label && <ButtonLabel {...props} />}
					<MapsSelect {...props} />
				</MapsProvider>
			);
	}
};

const ActionSelector = ({ action = '', name, control, label = '', actions = [], setValue, has_label }) => {
	return (
		<>
			<>
				<InputLabel>{label ? `${label} ` : ''}Action</InputLabel>
				<Controller
					name={name ? `${name}.action` : 'action'}
					control={control}
					defaultValue=""
					render={({ field: { onChange, value } }) => (
						<Select
							displayEmpty
							value={value}
							onChange={(e) => {
								onChange(e);
								setValue(name ? `${name}.data` : 'data', undefined);
								if (!has_label) setValue(name ? `${name}.label` : 'label', {});
							}}
						>
							{[...def_actions, ...actions].map((action) => (
								<MenuItem value={action} key={action}>
									{action_labels[action]}
								</MenuItem>
							))}
						</Select>
					)}
				/>
				<DataSelect label={label} control={control} name={name} action={action} has_label={has_label} />
			</>
		</>
	);
};

export default ActionSelector;
