import React, { useContext } from 'react';

import { Grid, IconButton, Table, Box, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Stack } from '@mui/material';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

import LanguagesContext from 'src/context/languages.jsx';
import ModalsContext from 'src/context/modals.jsx';

const TimelineSubmenuTable = ({ fields, remove, update, replace }) => {
	if (!fields?.length) return <></>;

	const modals_context = useContext(ModalsContext);
	const { def_lang } = useContext(LanguagesContext);

	return (
		<Grid item xs={12}>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell sx={{ pl: 3 }}>Name</TableCell>
							<TableCell align="right">Period</TableCell>
							<TableCell align="right" sx={{ pr: 3 }}>
								Actions
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{fields.map(({ name, period }, index) => (
							<TableRow key={index}>
								<TableCell sx={{ pl: 3 }}>
									<Typography align="left" variant="subtitle1">
										{name[def_lang]}
									</Typography>
								</TableCell>
								<TableCell align="right">{period?.join(' - ')}</TableCell>
								<TableCell sx={{ pr: 1, display: 'flex' }} align="right">
									<Stack direction="row">
										<Box sx={{ width: '100px', position: 'relative' }}>
											<IconButton
												sx={{ float: 'left', display: index === fields.length - 1 ? 'none' : '' }}
												color="primary"
												size="large"
												onClick={(event) => {
													event.stopPropagation();
													replace(index, 1);
												}}
											>
												<KeyboardDoubleArrowDownIcon />
											</IconButton>

											<IconButton
												sx={{ float: 'right', display: index === 0 ? 'none' : '' }}
												color="primary"
												size="large"
												onClick={(event) => {
													event.stopPropagation();
													replace(index, -1);
												}}
											>
												<KeyboardDoubleArrowUpIcon />
											</IconButton>
										</Box>
										<IconButton
											color="secondary"
											size="large"
											onClick={() =>
												modals_context.show('section_timeline_submenu_edit', {
													data: fields[index],
													clb: (data) => update(index, data),
												})
											}
										>
											<EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />
										</IconButton>
										<IconButton
											color="error"
											size="large"
											onClick={() =>
												modals_context.show('item_delete', {
													clb: () => remove(index),
												})
											}
										>
											<DeleteTwoToneIcon fontSize="small" />
										</IconButton>
									</Stack>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Grid>
	);
};

export default TimelineSubmenuTable;
