import { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

import LanguagesContext from '../context/languages.jsx';

const LanguagesTextFields = ({ name, control }) => {
	const { list: languages } = useContext(LanguagesContext);

	return languages.map((language) => (
		<Controller
			key={language.code}
			name={`${name}.${language.code}`}
			control={control}
			defaultValue=""
			render={({ field }) => <TextField {...field} fullWidth label={language.name} />}
		/>
	));
};

export default LanguagesTextFields;
