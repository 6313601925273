import React, { useContext, useEffect, useState } from 'react';
import { Button, Box, Stack } from '@mui/material';
import { useTheme } from '@emotion/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import ModalsContext from '../context/modals.jsx';

const RawButton = ({ on_click_raw }) => (
	<Button onClick={on_click_raw} sx={{ float: 'right', position: 'absolute', top: '-45px', right: 0 }}>
		Raw
	</Button>
);

const Editor = ({ onChange, value }) => {
	const [editor_html, set_editor_html] = useState(value);
	const [raw_html, set_raw_html] = useState(value);
	const [show_raw, set_show_raw] = useState(false);

	useEffect(() => {
		set_editor_html(value);
		set_raw_html(value);
	}, [value])

	const sync_views = (from_raw) => {
		if (from_raw) set_editor_html(raw_html);
		else set_raw_html(editor_html);
	};

	const handle_change = (html) => {
		set_editor_html(html);
		onChange(html);
	};

	const handle_change_raw = (html) => {
		set_raw_html(html);
		onChange(html);
	};

	const handle_click_show_raw = (event) => {
		event.preventDefault();
		set_show_raw(!show_raw);
		sync_views(show_raw);
	};

	return (
		<>
			<RawButton on_click_raw={handle_click_show_raw} />
			{show_raw ? (
				<textarea
					className={'raw-editor'}
					onChange={(e) => handle_change_raw(e.target.value)}
					value={raw_html}
					multiple
					style={{ minHeight: 'calc(100vh - 325px)', width: '100%', marginTop: '20px' }}
				/>
			) : (
				<ReactQuill onChange={handle_change} value={editor_html} theme={'snow'} />
			)}
		</>
	);
};

const HomeForm = () => {
	const theme = useTheme();
	const modals_context = useContext(ModalsContext);
	const [text, set_text] = useState(modals_context.data.data);

	return (
		<Box>
			<Stack
				sx={{
					'marginTop': '30px',
					'marginBottom': '10px',
					'position': 'relative',
					'& .quill': {
						'bgcolor': theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50',
						'borderRadius': '12px',
						'& .ql-toolbar': {
							bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.100',
							borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
							borderTopLeftRadius: '12px',
							borderTopRightRadius: '12px',
						},
						'& .ql-container': {
							'borderColor': theme.palette.mode === 'dark' ? `${theme.palette.dark.light + 20} !important` : 'primary.light',
							'borderBottomLeftRadius': '12px',
							'borderBottomRightRadius': '12px',
							'& .ql-editor': {
								minHeight: 'calc(100vh - 350px)',
							},
						},
					},
				}}>

				<Editor value={text} onChange={set_text} />
			</Stack>
			<Box textAlign="center">
				<Button variant="contained" sx={{ mt: 2 }} onClick={(event) => {
					event.preventDefault();
					modals_context.data.clb(text);
					modals_context.hide();
				}}>
					Save
				</Button>
			</Box>
		</Box >
	);
};

export default HomeForm;
