import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Divider, Grid, Stack, TextField, Autocomplete } from '@mui/material';

import { gridSpacing } from 'src/berry/store/constant.jsx';
import Chip from 'src/berry/ui-component/extended/Chip.jsx';
import InputLabel from 'src/berry/ui-component/extended/Form/InputLabel.jsx';

import ModalsContext from 'src/context/modals.jsx';
import LanguagesTextareas from 'src/ui/languages_textarea.jsx';

const SectionSmallCardsSubmenuForm = ({ action }) => {
	const {
		hide,
		data: { data, clb, all_categories },
	} = useContext(ModalsContext);

	const { handleSubmit, control } = useForm({ defaultValues: data || {} });
	const [categories, set_categories] = useState(data?.categories || []);

	const on_submit = (data) => {
		clb({ ...data, categories });
		hide();
	};

	return (
		<>
			<Grid container spacing={gridSpacing} sx={{ pb: '30px' }}>
				<Grid item xs={12} md={6}>
					<Stack spacing={gridSpacing}>
						<InputLabel>Name</InputLabel>
						<LanguagesTextareas name="name" control={control} />
					</Stack>
				</Grid>

				<Grid item xs={12} md={6}>
					<Stack spacing={gridSpacing}>
						<InputLabel>Categories</InputLabel>
						<Autocomplete
							multiple
							freeSolo
							options={all_categories || []}
							getOptionLabel={(option) => option}
							value={categories || []}
							onChange={(event, new_categories) => {
								set_categories((categories) => Array.from(new Set([...categories, new_categories[new_categories.length - 1]])));
							}}
							renderInput={(params) => <TextField {...params} />}
							filterOptions={(options, params) => {
								const filtered = options.filter((option) => option.includes(params.inputValue));
								if (params.inputValue !== '') {
									filtered.push(params.inputValue);
								}
								return filtered;
							}}
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip
										key={option}
										label={option}
										{...getTagProps({ index })}
										onDelete={() => {
											const updated_categories = [...categories];
											updated_categories.splice(index, 1);
											set_categories(updated_categories);
										}}
									/>
								))
							}
						/>
					</Stack>
				</Grid>
			</Grid>

			<Grid container>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item xs={12}>
					<Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end" sx={{ mt: '15px' }}>
						<Button color="error" onClick={hide}>
							Cancel
						</Button>
						<Button variant="contained" size="small" onClick={handleSubmit(on_submit)}>
							{action === 'edit' ? 'Save' : 'Add'}
						</Button>
					</Stack>
				</Grid>
			</Grid>
		</>
	);
};

export default SectionSmallCardsSubmenuForm;
