export const array_move = (array, i, direction) => {
	const item = array.splice(i, 1)[0];
	array.splice(i + direction, 0, item);
	return array;
};

export const search_by_name = (array, search_value) => {
	const search_value_lower_case = search_value.toLowerCase();
	return array.filter(({ name }) => name.toLowerCase().includes(search_value_lower_case));
};

export const get_all_categories = (submenu) => Array.from(new Set(submenu?.flatMap((s) => s?.categories)));
