import { memo } from 'react';

// material-ui
import { Typography } from '@mui/material';
import { IconChartArcs, IconLanguage, IconForms, IconFile3d, IconAppWindow, IconMap2 } from '@tabler/icons';

// project imports
import NavGroup from './NavGroup';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const menu_items = [
	{
		id: 'dashboard',
		title: 'Dashboard',
		icon: IconChartArcs,
		type: 'group',
		children: [
			{
				id: 'languages',
				title: 'Languages',
				type: 'item',
				url: '/dashboard/languages',
				icon: IconLanguage,
			},
			{
				id: 'sections',
				title: 'Sections',
				type: 'item',
				url: '/dashboard/sections',
				icon: IconForms,
			},
			{
				id: 'popups',
				title: 'Popups',
				type: 'item',
				url: '/dashboard/popups',
				icon: IconAppWindow,
			},
			{
				id: 'maps',
				title: 'Maps',
				type: 'item',
				url: '/dashboard/maps',
				icon: IconMap2,
			},
			{
				id: 'file_manager',
				title: 'File Manager',
				type: 'item',
				url: '/dashboard/file_manager',
				icon: IconFile3d,
			},
		],
	},
];

const MenuList = () => {
	const navItems = menu_items.map((item) => {
		switch (item.type) {
			case 'group':
				return <NavGroup key={item.id} item={item} />;
			default:
				return (
					<Typography key={item.id} variant="h6" color="error" align="center">
						Menu Items Error
					</Typography>
				);
		}
	});

	return <>{navItems}</>;
};

export default memo(MenuList);
