import React, { useState, useContext } from 'react';

import JSONContext from './json.jsx';

const LanguagesContext = React.createContext();

function LanguagesProvider({ children }) {
	const { get_languages, set_languages } = useContext(JSONContext);
	const languages = get_languages();

	const store = {
		get list() {
			return languages;
		},
		async add(item) {
			await set_languages([...languages, item]);
		},
		edit(updated_item, id) {
			languages[id] = updated_item;
			set_languages(languages);
		},
		remove(id) {
			languages.splice(id, 1);
			set_languages(languages);
		},
		get def_lang() {
			if (!languages.find(({ code }) => code === 'en')) return languages[0].code;

			return 'en';
		},
	};

	return <LanguagesContext.Provider value={store}>{children}</LanguagesContext.Provider>;
}

export default LanguagesContext;
export { LanguagesProvider };
