import Maps from 'src/components/dashboard_maps/maps.jsx';
import { MapsProvider } from 'src/context/maps';

const Page = () => {
	return (
		<MapsProvider>
			<Maps />
		</MapsProvider>
	);
};

export default Page;
