import React, { useContext, useState } from 'react';
import { Button, Box, TextField, Grid } from '@mui/material';

import ModalsContext from '../context/modals.jsx';

const MapForm = () => {
	const modals_context = useContext(ModalsContext);

	const [uid, set_uid] = useState('');

	const handle_submit = (event) => {
		event.preventDefault();
		modals_context.data.clb({ uid });
		modals_context.hide();
	};

	return (
		<form onSubmit={handle_submit}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<TextField required id="map_name" name="map_name" label="Name" fullWidth value={uid} onChange={(e) => set_uid(e.target.value)} />
				</Grid>
			</Grid>
			<Box textAlign="center">
				<Button variant="contained" type="submit" sx={{ mt: 2 }}>
					Add
				</Button>
			</Box>
		</form>
	);
};

export default MapForm;
