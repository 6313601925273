import Popup from 'src/components/dashboard_popup/popup.jsx';

import { FilesProvider } from 'src/context/files.jsx';
import { PopupsProvider } from 'src/context/popups.jsx';

const Page = () => (
	<FilesProvider>
		<PopupsProvider>
			<Popup />
		</PopupsProvider>
	</FilesProvider>
);

export default Page;
