import { useContext, useState } from 'react';
import { Divider, Grid, Stack, Button, TextField, IconButton, Box, InputAdornment } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';

import InputLabel from 'src/berry/ui-component/extended/Form/InputLabel.jsx';
import { gridSpacing } from 'src/berry/store/constant.jsx';
import CONFIG from 'src/config.json';

import LanguagesTextFields from 'src/ui/languages_text_fields.jsx';
import LanguagesWysiwygTextarea from 'src/ui/languages_wysiwyg_textarea.jsx';
import ModalsContext from 'src/context/modals.jsx';
import MainCard from 'src/berry/ui-component/cards/MainCard';

const CoordinatesSelector = ({ close, marker_position, img, handle_coordinate_selection }) => {
	return (
		<MainCard
			sx={{
				'position': 'fixed',
				'top': '50%',
				'right': '50%',
				'transform': 'translate(50%, -50%)',
				'zIndex': 1201,
				'width': 'calc(100vw - 50px)',
				'height': 'calc(100vh - 50px)',
				'boxShadow': '10px 2px 100px rgb(0, 0, 0, 50%)',
				'mt': '0 !important',
				'&:hover': {
					boxShadow: '10px 2px 100px rgb(0, 0, 0, 50%)',
				},
			}}
			title={`Choose Coordinates`}
			secondary={
				<IconButton onClick={close} size="large">
					<CloseIcon fontSize="small" />
				</IconButton>
			}
		>
			<Grid container spacing={gridSpacing} sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 200px)', margin: 0, width: '100%' }}>
				<Box onClick={handle_coordinate_selection} sx={{ position: 'relative' }}>
					<img src={`${CONFIG.api}/app_data/files/${img}`} alt="Image" />

					{marker_position && (
						<Box
							position="absolute"
							top={`${marker_position.y}%`}
							left={`${marker_position.x}%`}
							sx={{ border: '1px solid #00000080', backgroundColor: 'white', transform: 'translate3d(-50%, -50%, 0px)' }}
							width="43px"
							height="43px"
							borderRadius="50%"
						/>
					)}
				</Box>
			</Grid>
		</MainCard>
	);
};

const PlaceForm = ({ action }) => {
	const {
		hide,
		data: { data, clb, img },
	} = useContext(ModalsContext);

	const { handleSubmit, control, setValue } = useForm({ defaultValues: data || {} });
	const [open_map, set_open_map] = useState(false);
	const [marker_position, set_marker_position] = useState({ x: data?.x, y: data?.y });

	const on_submit = (data) => {
		clb(data);
		hide();
	};

	const handle_coordinate_selection = (event) => {
		const rect = event.target.getBoundingClientRect();
		const x = ((event.clientX - rect.left) / rect.width) * 100;
		const y = ((event.clientY - rect.top) / rect.height) * 100;
		set_marker_position({ x, y });
		setValue('x', x);
		setValue('y', y);
	};

	return (
		<>
			{open_map && (
				<CoordinatesSelector
					close={() => set_open_map(false)}
					marker_position={marker_position}
					img={img}
					handle_coordinate_selection={handle_coordinate_selection}
				/>
			)}
			<Grid container spacing={gridSpacing} sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 250px)', pb: '30px' }}>
				<Grid item md={12}>
					<Stack spacing={gridSpacing}>
						<InputLabel>Name</InputLabel>
						<LanguagesTextFields name="name" control={control} />
					</Stack>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={12}>
					<Stack spacing={gridSpacing}>
						<InputLabel>Description</InputLabel>
						<LanguagesWysiwygTextarea name="desc" control={control} />
					</Stack>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={12}>
					<Button variant="outlined" color={img ? 'primary' : 'error'} onClick={() => (img ? set_open_map(true) : null)} fullWidth>
						{img ? 'Set coordinates on the map' : 'Add main image'}
					</Button>
				</Grid>

				{img && (
					<>
						<Grid item xs={12}>
							<Stack spacing={gridSpacing}>
								<InputLabel>Coordinates</InputLabel>
								<Stack direction="row" spacing={gridSpacing}>
									<Grid item md={6}>
										<Controller
											name="x"
											control={control}
											defaultValue=""
											render={({ field: { value, onChange } }) => (
												<TextField
													value={value}
													onChange={(e) => {
														onChange(e);
														set_marker_position({ ...marker_position, x: Number(e.target.value) });
													}}
													type="number"
													fullWidth
													label="X"
													InputProps={{
														endAdornment: <InputAdornment position="end">%</InputAdornment>,
													}}
												/>
											)}
										/>
									</Grid>
									<Grid item md={6}>
										<Controller
											name="y"
											control={control}
											defaultValue=""
											render={({ field: { value, onChange } }) => (
												<TextField
													value={value}
													onChange={(e) => {
														onChange(e);
														set_marker_position({ ...marker_position, y: Number(e.target.value) });
													}}
													type="number"
													fullWidth
													label="Y"
													InputProps={{
														endAdornment: <InputAdornment position="end">%</InputAdornment>,
													}}
												/>
											)}
										/>
									</Grid>
								</Stack>
							</Stack>
						</Grid>
					</>
				)}
			</Grid>

			<Grid container>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item xs={12}>
					<Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end" sx={{ mt: '15px' }}>
						<Button color="error" onClick={hide}>
							Cancel
						</Button>
						<Button variant="contained" size="small" onClick={handleSubmit(on_submit)}>
							{action === 'edit' ? 'Save' : 'Add'}
						</Button>
					</Stack>
				</Grid>
			</Grid>
		</>
	);
};

export default PlaceForm;
