import React, { useContext, useState } from 'react';

import ModalsContext from '../context/modals.jsx';
import { Button, Box, TextField, Grid } from '@mui/material';

const LanguageForm = ({ action }) => {
	const {
		hide,
		data: { data = {}, clb },
	} = useContext(ModalsContext);

	const [code, set_code] = useState(data?.code || '');
	const [name, set_name] = useState(data?.name || '');

	const submit = (event) => {
		event.preventDefault();
		clb({ code, name });
		hide();
	};

	return (
		<form onSubmit={submit}>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<TextField
						required
						id="language_code"
						name="language_code"
						label="Language code"
						fullWidth
						inputProps={{ maxLength: 3 }}
						value={code}
						onChange={(e) => set_code(e.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						required
						id="language_name"
						name="language_name"
						label="Language Name"
						fullWidth
						value={name}
						onChange={(e) => set_name(e.target.value)}
					/>
				</Grid>
			</Grid>
			<Box textAlign="center">
				<Button variant="contained" type="submit" sx={{ mt: 2 }}>
					{action === 'add' ? 'Add' : 'Save'}
				</Button>
			</Box>
		</form>
	);
};

export default LanguageForm;
