import { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Divider, Grid, Stack, TextField, Button } from '@mui/material';

import MainCard from 'src/berry/ui-component/cards/MainCard.jsx';
import InputLabel from 'src/berry/ui-component/extended/Form/InputLabel.jsx';
import { gridSpacing } from 'src/berry/store/constant.jsx';

import SectionsContext from 'src/context/sections.jsx';
import ModalsContext from 'src/context/modals.jsx';
import LanguagesTextFields from 'src/ui/languages_text_fields.jsx';
import { array_move } from 'src/utils/index.js';

import TimelineSubmenuTable from './timeline_submenu_table.jsx';
import EventsTable from './timeline_events_table.jsx';
import FileSelector from '../file_selector/component.jsx';

const SectionTimeline = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { list, set } = useContext(SectionsContext);
	const modals_context = useContext(ModalsContext);

	const section = list[id];

	const { uid, name, start, end, bkg } = section;

	const { handleSubmit, control } = useForm({
		defaultValues: { uid, name, start, end },
	});

	const back_to_list = () => navigate('/dashboard/sections/');

	const on_sumbit = (data) => {
		list[id] = { ...list[id], ...data };
		set(list);
		back_to_list();
	};

	return (
		<MainCard title="Edit Section">
			<form onSubmit={handleSubmit(on_sumbit)}>
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12}>
						<Stack spacing={gridSpacing}>
							<Controller
								name="uid"
								control={control}
								defaultValue=""
								render={({ field }) => <TextField {...field} type="text" fullWidth label="UID" />}
							/>
						</Stack>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid item md={6}>
						<Stack spacing={gridSpacing}>
							<InputLabel>Name</InputLabel>
							<LanguagesTextFields name="name" control={control} />
						</Stack>
					</Grid>

					<Grid item xs={6}>
						<Stack spacing={gridSpacing}>
							<InputLabel>Full Dates</InputLabel>
							<Stack direction="row" spacing={gridSpacing}>
								<Grid item md={6}>
									<Controller
										name="start"
										control={control}
										defaultValue=""
										render={({ field }) => <TextField {...field} type="number" fullWidth label="Start" />}
									/>
								</Grid>
								<Grid item md={6}>
									<Controller
										name="end"
										control={control}
										defaultValue=""
										render={({ field }) => <TextField {...field} type="number" fullWidth label="End" />}
									/>
								</Grid>
							</Stack>
						</Stack>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid item xs={12}>
						<Stack spacing={2}>
							<InputLabel>Background Image</InputLabel>
							<FileSelector
								on_change={(data) => {
									section.bkg = data;
									set(list);
								}}
								value={bkg}
							/>
						</Stack>
					</Grid>

					<Grid item xs={12}>
						<InputLabel>Submenu</InputLabel>
					</Grid>

					<TimelineSubmenuTable
						fields={section?.submenu}
						remove={(index) => {
							section.submenu.splice(index, 1);
							set(list);
						}}
						update={(index, data) => {
							section.submenu[index] = data;
							set(list);
						}}
						replace={(i, direction) => {
							section.submenu = array_move(section.submenu, i, direction);
							set(list);
						}}
					/>

					<Grid item xs={12}>
						<Button
							variant="text"
							onClick={() =>
								modals_context.show('section_timeline_submenu_add', {
									clb: (data) => {
										if (!section.submenu) section.submenu = [];
										section.submenu.push(data);
										set(list);
									},
								})
							}
						>
							Add Submenu
						</Button>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid item xs={12}>
						<InputLabel>Events</InputLabel>
					</Grid>

					<EventsTable
						fields={section?.events.sort((a,b)=>a.date-b.date)}
						remove={(index) => {
							section.events.splice(index, 1);
							set(list);
						}}
						update={(index, data) => {
							section.events[index] = data;
							set(list);
						}}
					/>

					<Grid item xs={12}>
						<Button
							variant="text"
							onClick={() =>
								modals_context.show('section_event_add', {
									clb: (data) => {
										if (!section.events) section.events = [];
										section.events.push(data);
										set(list);
									},
								})
							}
						>
							Add Event
						</Button>
					</Grid>

					<Grid item xs={6}>
						<Button fullWidth variant="text" color="error" onClick={back_to_list}>
							Cancel
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button fullWidth variant="contained" type="submit">
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
		</MainCard>
	);
};

export default SectionTimeline;
