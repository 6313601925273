import { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Divider, Grid, Stack, TextField } from '@mui/material';

import InputLabel from 'src/berry/ui-component/extended/Form/InputLabel.jsx';
import { gridSpacing } from 'src/berry/store/constant.jsx';

import ModalsContext from 'src/context/modals.jsx';
import LanguagesTextareas from 'src/ui/languages_textarea.jsx';

const SectionTimelineSubmenuForm = ({ action }) => {
	const {
		hide,
		data: { data, clb },
	} = useContext(ModalsContext);

	const { handleSubmit, control } = useForm({ defaultValues: data || {} });

	const on_submit = (data) => {
		clb(data);
		hide();
	};

	return (
		<>
			<Grid container spacing={gridSpacing} sx={{ pb: '30px' }}>
				<Grid item xs={12} md={6}>
					<Stack spacing={gridSpacing}>
						<InputLabel>Name</InputLabel>
						<LanguagesTextareas name="name" control={control} />
					</Stack>
				</Grid>

				<Grid item xs={12} md={6}>
					<InputLabel sx={{ mb: gridSpacing }}>Full Dates</InputLabel>
					<Stack direction="row" spacing={gridSpacing}>
						<Grid item md={6}>
							<Controller
								name="period[0]"
								control={control}
								defaultValue=""
								render={({ field }) => <TextField {...field} type="number" fullWidth label="Start" />}
							/>
						</Grid>

						<Grid item md={6}>
							<Controller
								name="period[1]"
								control={control}
								defaultValue=""
								render={({ field }) => <TextField {...field} type="number" fullWidth label="End" />}
							/>
						</Grid>
					</Stack>
				</Grid>
			</Grid>

			<Grid container>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item xs={12}>
					<Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end" sx={{ mt: '15px' }}>
						<Button color="error" onClick={hide}>
							Cancel
						</Button>
						<Button variant="contained" size="small" onClick={handleSubmit(on_submit)}>
							{action === 'edit' ? 'Save' : 'Add'}
						</Button>
					</Stack>
				</Grid>
			</Grid>
		</>
	);
};

export default SectionTimelineSubmenuForm;
