import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import PopupsContext from 'src/context/popups.jsx';
import Regular from './regular.jsx';
import Viewer from './viewer.jsx';

const popup_types = {
	regular: Regular,
	viewer: Viewer,
};

const Popup = () => {
	const { id } = useParams();
	const { list } = useContext(PopupsContext);
	const popup = list[id];

	const Component = popup_types[popup.type];
	return <Component />;
};

export default Popup;
