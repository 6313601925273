import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

import MainCard from '../berry/ui-component/cards/MainCard.jsx';
import LanguagesContext from 'src/context/languages.jsx';
import SectionsContext, { SectionsProvider } from '../context/sections.jsx';
import ModalsContext from '../context/modals.jsx';
import { array_move } from '../utils/index.js';

const Body = ({ data }) => {
	const navigate = useNavigate();
	const modals_context = useContext(ModalsContext);
	const sections_context = useContext(SectionsContext);
	const { def_lang } = useContext(LanguagesContext);

	const covert_type = (type) => {
		switch (type) {
			case 'timeline':
				return 'Timeline';
			case 'small_cards':
				return 'Small Cards';
			case 'big_cards':
				return 'Big Cards';
		}
	};

	const handle_replace_section = (i, direction) => {
		sections_context.set(array_move(data, i, direction));
	};

	return (
		<TableBody>
			{data.map(({ name, uid, type }, i) => {
				return (
					<TableRow
						hover
						onClick={() => {
							navigate(`/dashboard/sections/${i}`);
						}}
						key={i}
						style={{ cursor: 'pointer' }}
					>
						<TableCell sx={{ pl: 4 }}>{uid}</TableCell>

						<TableCell width="100%">{name?.[def_lang]}</TableCell>

						<TableCell>{covert_type(type)}</TableCell>
						<TableCell
							sx={{
								display: 'flex',
							}}
						>
							<Box sx={{ width: '100px', position: 'relative' }}>
								<IconButton
									sx={{ float: 'left', display: i === data.length - 1 ? 'none' : '' }}
									color="primary"
									size="large"
									onClick={(event) => {
										event.stopPropagation();
										handle_replace_section(i, 1);
									}}
								>
									<KeyboardDoubleArrowDownIcon />
								</IconButton>

								<IconButton
									sx={{ float: 'right', display: i === 0 ? 'none' : '' }}
									color="primary"
									size="large"
									onClick={(event) => {
										event.stopPropagation();
										handle_replace_section(i, -1);
									}}
								>
									<KeyboardDoubleArrowUpIcon />
								</IconButton>
							</Box>

							<IconButton
								color="primary"
								size="large"
								onClick={(event) => {
									event.stopPropagation();
									modals_context.show('section_delete', {
										clb: () => {
											sections_context.remove(i);
										},
									});
								}}
							>
								<DeleteIcon sx={{ fontSize: '1.3rem' }} />
							</IconButton>
						</TableCell>
					</TableRow>
				);
			})}
		</TableBody>
	);
};

const Sections = () => {
	const modals_context = useContext(ModalsContext);
	const sections_context = useContext(SectionsContext);

	return (
		<>
			<Button
				variant="contained"
				type="button"
				onClick={() =>
					modals_context.show('section_add', {
						clb: (data) => {
							sections_context.add(data);
						},
					})
				}
				sx={{ mb: 2 }}
			>
				Add section
			</Button>
			{sections_context.list.length ? (
				<MainCard content={false}>
					<TableContainer>
						<Table style={{ whiteSpace: 'nowrap', width: '100%' }}>
							<TableHead>
								<TableRow>
									<TableCell sx={{ pl: 4 }}>UID</TableCell>
									<TableCell>Name</TableCell>
									<TableCell>Type</TableCell>
									<TableCell align="right" sx={{ pr: 4 }}>
										Action
									</TableCell>
								</TableRow>
							</TableHead>
							<Body data={sections_context.list} />
						</Table>
					</TableContainer>
				</MainCard>
			) : (
				<Typography variant="subtitle1" sx={{ color: 'grey.900' }} align="center">
					There are no sections
				</Typography>
			)}
		</>
	);
};

const Page = () => {
	return (
		<SectionsProvider>
			<Sections />
		</SectionsProvider>
	);
};

export default Page;
