import React, { useContext } from 'react';

import JSONContext from './json.jsx';

const MapsContext = React.createContext();

function MapsProvider({ children }) {
	const { get_maps, set_maps } = useContext(JSONContext);
	const maps = get_maps();

	const store = {
		get list() {
			return maps;
		},

		async add(item) {
			await set_maps([...maps, item]);
		},

		remove(id) {
			maps.splice(id, 1);
			set_maps(maps);
		},

		async set(maps) {
			await set_maps(maps);
		},
	};

	return <MapsContext.Provider value={store}>{children}</MapsContext.Provider>;
}

export default MapsContext;
export { MapsProvider };
