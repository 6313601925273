import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Divider, Grid, Stack, Button } from '@mui/material';

import InputLabel from 'src/berry/ui-component/extended/Form/InputLabel.jsx';
import MainCard from 'src/berry/ui-component/cards/MainCard.jsx';
import { gridSpacing } from 'src/berry/store/constant.jsx';

import ModalsContext from 'src/context/modals.jsx';
import LanguagesTextFields from 'src/ui/languages_text_fields.jsx';
import MapsContext from 'src/context/maps.jsx';
import LanguagesWysiwygTextarea from 'src/ui/languages_wysiwyg_textarea.jsx';

import FileSelector from '../file_selector/component.jsx';
import PlacesTable from './places_table.jsx';

const Map = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { list, set } = useContext(MapsContext);
	const modals_context = useContext(ModalsContext);

	const map = list[id];

	const { handleSubmit, control, watch, setValue } = useForm({
		defaultValues: map || {},
	});

	const back_to_list = () => navigate('/dashboard/maps/');

	const on_sumbit = (data) => {
		list[id] = { ...list[id], ...data };
		set(list);
		back_to_list();
	};

	return (
		<MainCard title="Edit Map">
			<form onSubmit={handleSubmit(on_sumbit)}>
				<Grid container spacing={gridSpacing}>
					<Grid item md={6}>
						<Stack spacing={gridSpacing}>
							<InputLabel>Name</InputLabel>
							<LanguagesTextFields name="name" control={control} />
						</Stack>
					</Grid>

					<Grid item md={6}>
						<Stack spacing={gridSpacing}>
							<InputLabel>Subheader</InputLabel>
							<LanguagesTextFields name="sub" control={control} />
						</Stack>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid item xs={6}>
						<Stack spacing={2}>
							<InputLabel>Image</InputLabel>
							<FileSelector
								on_change={(data) => {
									map.img = data;
									setValue('img', data);
									set(list);
								}}
								value={map.img}
							/>
						</Stack>
					</Grid>

					<Grid item md={6}>
						<Stack spacing={gridSpacing}>
							<InputLabel>Back Button Label</InputLabel>
							<LanguagesTextFields name="back_label" control={control} />
						</Stack>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid item xs={12}>
						<Stack spacing={gridSpacing}>
							<InputLabel>Description</InputLabel>
							<LanguagesWysiwygTextarea name="desc" control={control} />
						</Stack>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid item xs={12}>
						<InputLabel>Places</InputLabel>
					</Grid>

					<PlacesTable
						fields={map?.places}
						remove={(index) => {
							list[id].places.splice(index, 1);
							set(list);
						}}
						update={(index, data) => {
							list[id].places[index] = data;
							setValue('places', list[id].places);
							set(list);
						}}
						img={watch('img')}
					/>

					<Grid item xs={12}>
						<Button
							variant="text"
							onClick={() =>
								modals_context.show('maps_place_add', {
									clb: (data) => {
										if (!list[id].places) map.places = [];
										list[id].places.push(data);
										setValue('places', list[id].places);
										set(list);
									},
									img: watch('img'),
								})
							}
						>
							Add Place
						</Button>
					</Grid>

					<Grid item xs={6}>
						<Button fullWidth variant="text" color="error" onClick={back_to_list}>
							Cancel
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button fullWidth variant="contained" type="submit">
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
		</MainCard>
	);
};

export default Map;
