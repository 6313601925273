import { IconButton, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import MainCard from '../berry/ui-component/cards/MainCard';
import { useNavigate } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';

import PopupsContext, { PopupsProvider } from '../context/popups';
import React, { useContext } from 'react';
import ModalsContext from '../context/modals';

const Body = ({ data }) => {
	const navigate = useNavigate();
	const modals_context = useContext(ModalsContext);
	const popups_context = useContext(PopupsContext);

	const covert_type = (type) => {
		switch (type) {
			case 'regular':
				return 'Regular';
			case 'viewer':
				return 'Viewer';
		}
	};

	return (
		<TableBody>
			{data.map(({ uid, type }, i) => {
				return (
					<TableRow
						hover
						onClick={() => {
							navigate(`/dashboard/popups/${i}`);
						}}
						key={i}
					>
						<TableCell width="100%" sx={{ pl: 4 }}>
							{uid}
						</TableCell>
						<TableCell>{covert_type(type)}</TableCell>
						<TableCell>
							<IconButton
								color="primary"
								size="large"
								onClick={(event) => {
									event.stopPropagation();
									modals_context.show('popup_delete', {
										clb: () => {
											popups_context.remove(i);
										},
									});
								}}
							>
								<DeleteIcon sx={{ fontSize: '1.3rem' }} />
							</IconButton>
						</TableCell>
					</TableRow>
				);
			})}
		</TableBody>
	);
};

const Popups = () => {
	const modals_context = useContext(ModalsContext);
	const popups_context = useContext(PopupsContext);

	return (
		<>
			<Button
				variant="contained"
				type="button"
				onClick={() =>
					modals_context.show('popup_add', {
						clb: (data) => {
							popups_context.add(data);
						},
					})
				}
				sx={{ mb: 2 }}
			>
				Add popup
			</Button>
			{popups_context.list.length ? (
				<MainCard content={false}>
					<TableContainer>
						<Table style={{ whiteSpace: 'nowrap', width: '100%' }}>
							<TableHead>
								<TableRow>
									<TableCell sx={{ pl: 4 }}>UID</TableCell>
									<TableCell>Type</TableCell>
									<TableCell align="center" sx={{ pr: 4 }}>
										Action
									</TableCell>
								</TableRow>
							</TableHead>
							<Body data={popups_context.list} />
						</Table>
					</TableContainer>
				</MainCard>
			) : (
				<Typography variant="subtitle1" sx={{ color: 'grey.900' }} align="center">
					There are no popups
				</Typography>
			)}
		</>
	);
};

const Page = () => {
	return (
		<PopupsProvider>
			<Popups />
		</PopupsProvider>
	);
};

export default Page;
