import React, { useContext } from 'react';

import JSONContext from './json.jsx';

const HomeContext = React.createContext();

function HomeProvider({ children }) {
	const { get_home, set_home} = useContext(JSONContext);
	const home = get_home();

	const store = {
		get home() {
			return home;
		},
		set(home) {
			set_home(home);
		}
	};

	return <HomeContext.Provider value={store}>{children}</HomeContext.Provider>;
}

export default HomeContext;
export { HomeProvider };
