import React, { useContext } from 'react';

import JSONContext from './json.jsx';

const PopupsContext = React.createContext();

function PopupsProvider({ children }) {
	const { get_popups, set_popups } = useContext(JSONContext);
	const popups = get_popups();

	const store = {
		get list() {
			return popups;
		},
		async add(item) {
			await set_popups([...popups, item]);
		},
		remove(id) {
			popups.splice(id, 1);
			set_popups(popups);
		},
		async set(sections) {
			await set_popups(sections);
		},
	};

	return <PopupsContext.Provider value={store}>{children}</PopupsContext.Provider>;
}

export default PopupsContext;
export { PopupsProvider };
